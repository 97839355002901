<template>
  <v-data-table :items="licences" :headers="headers" :mobile-breakpoint="900">
    <template v-slot:top>
      <!--        Delete Modal-->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Are you sure you want to delete your {{ editedItem.stateOfIssueName }} issued
            {{ editedItem.licenceTitle }}?
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-toolbar flat>
        <v-toolbar-title>Licences</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer/>
        <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="newItem"
            v-if="isEditable"
        >
          <span>New</span>
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.totalLicenceRatings="{item }">
      {{ item.totalLicenceRatings }} {{ item.totalLicenceRatingsExpired >0 ? `(${item.totalLicenceRatingsExpired} expired)`:''}}
    </template>
    <template v-slot:item.totalLicenceCertificates="{item }">
      {{ item.totalLicenceCertificates }} {{ item.totalLicenceCertificatesExpired >0 ? `(${item.totalLicenceCertificatesExpired} expired)`:''}}
    </template>
    <template v-slot:item.licenceDateOfInitialIssue="{item}">
      {{formatDate(item.licenceDateOfInitialIssue,'dd MMM yyyy')}}
    </template>
    <template v-slot:item.lastUpdated="{item }">
      {{formatDate(item.lastUpdated,'dd MMM yyyy HH:mm:ss')}}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
      >
        {{isEditable ? 'mdi-pencil': 'mdi-eye'}}
      </v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
          v-if="isEditable"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <div class="text-body-2">No Entries.</div>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {LicenceHttp} from "@/http/LicenceHttp";
import {format} from 'date-fns'

export default {
  name: "LicenceManager",
  props: {
    person: {
      type: String,
      required: true
    },
    isEditable:{
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState("licence", ["licences"]),
  },
  methods: {
    ...mapActions("licence", ["setLicences", "setActiveLicence"]),

    formatDate(item,formatString){
     return format(new Date(item),formatString.toString())
    },
    async newItem() {
      await this.setActiveLicence(this.defaultItem)
      await this.$router.push(`Licence`)
    },
    async editItem(item) {
      await this.setActiveLicence(item)
      await this.$router.push(`Licence`)
    },

    deleteItem(item) {
      this.editedIndex = item._id
      this.editedItem = Object.assign({}, item)
      this.editedItem.licenceDateOfInitialIssue = this.editedItem.licenceDateOfInitialIssue.substring(0, 10)
      this.dialogDelete = true
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async deleteItemConfirm() {
      await LicenceHttp.removeLicence(this.editedIndex)
      this.refresh()
      this.closeDelete()
    },

    async refresh() {
      await this.setLicences(this.person)
    }
  },
  data() {
    return {
      dialogDelete: false,
      defaultItem: {
        linkedPersonId: this.$route.params.id,
        stateOfIssueName: "",
        licenceType: "",
        licenceTitle: "",
        licenceCategory: "",
        licenceDateOfInitialIssue: "",
        licenceCountryCode: "",
        licenceSerialNumber: "",
        licenceRatings: [],
        licenceCertificates: [],
        licenceIsSuspended: false

      },
      editedIndex: -1,
      editedItem: {
        linkedPersonId: this.$route.params.id,
        stateOfIssueName: "",
        licenceType: "",
        licenceTitle: "",
        licenceCategory: "",
        licenceDateOfInitialIssue: "",
        licenceCountryCode: "",
        licenceSerialNumber: "",
        licenceRatings: [],
        licenceCertificates: [],
        certificateOfRevalidation: [],
        licenceIsSuspended: false
      },
      headers: [
        {text: 'State of Licence Issue', value: 'stateOfIssueName'},
        {text: 'Licence Type', value: 'licenceTitle'},
        {text: 'Date of Initial Issue', value: 'licenceDateOfInitialIssue'},
        {text: 'Country Code', value: 'licenceCountryCode'},
        {text: 'Licence serial Number', value: 'licenceSerialNumber'},
        {text: 'Total Ratings', value: 'totalLicenceRatings'},
        {text: 'Total Certificates', value: 'totalLicenceCertificates'},
        {text: 'Last updated', value: 'lastUpdated'},
        {text: 'Actions', value: 'actions'},
      ],
    }
  },
  watch: {
    async person(newValue) {
      await this.setLicences(newValue)
    }
  }
}
</script>

<style scoped>

</style>