<template>
  <licence-manager :person="this.$route.params.id" v-on:commitChanges="save" :is-editable="isEditable" />
</template>

<script>
import LicenceManager from "@/components/licence/LicenceManager";
import {mapActions, mapState} from "vuex";
import {isAuthorised} from "@/util/helpers";
export default {
  name: "LicencesPage",
  components: {LicenceManager},
  methods: {
    ...mapActions("licence",["setLicences"]),
    ...mapActions("person", ["setPersons"]),
    async save() {
      console.log("i Got Here")
    },
  },
  computed:{
    ...mapState("licence",["licences"]),
    ...mapState("person",["activePerson","loggedInPerson"]),
    ...mapState("user", ["accountRoles"]),
      isEditable() {
        return this.activePerson._id === this.loggedInPerson._id || isAuthorised(this.accountRoles,["rootAdmin","admin"])
      }
  },
  mounted(){
    this.setLicences(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>